import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import shape from '../../assets/images/shape/shape1.svg'

const TestimonialsStyleTwo = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p>Our customer-centric approach is our secret! Our tailored approach to developing software means more releases and less waiting.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>We are enjoying the journey. theParse is doing a great job working on my brand. The team has simplified the process from requirements gathering to delivering the solution.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client1} alt="about" />
                                    <div className="title">
                                        <h3>Thando Phenyane</h3>
                                        <span>Founder Mboma Gallery</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={client3} className="shadow-sm" alt="about" />
                            <p>Working with theParse to develop our e-commerce website has been an absolute delight. As a female entrepreneur, I was initially concerned about finding a team that truly understood my vision and the essence of my brand. From the very beginning, theParse not only understood but also embraced the unique concept I wanted to bring to life.</p>
                            <div className="client-info">
                                <h3>Anelisa M</h3>
                                <span>Co-Founder active360™</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={client3} className="shadow-sm" alt="about" />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <div className="client-info">
                                <h3>Sarah Taylor</h3>
                                <span>CEO at at theParse</span>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={client1} className="shadow-sm" alt="about" />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <div className="client-info">
                                <h3>Alex Maxwell</h3>
                                <span>CEO at theParse</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="about" />
            </div>
        </div>
    )
}

export default TestimonialsStyleTwo